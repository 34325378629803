import { FaCheck } from 'react-icons/fa6'
import { LuInfo }  from 'react-icons/lu'

import bemit from '../Functions/bemit'

export default function Message (props)
{
	const modifiers = (props.bemit || []).slice(0);

	if (!props.children) return null;

	if (props.color) modifiers.push(props.color);
	if (props.background) modifiers.push('bg-' + props.bg);
	if (props['center-msg']) modifiers.push('center-msg');

	const Icon = typeof props.Icon === 'function'
	? props.Icon
	: props.icon === 'checked'
	? FaCheck
	: props.icon === 'info'
	? LuInfo
	: undefined

	const icon = Icon
	? <Icon className="c-message__icon" />
	: undefined;

	if (props.icon === 'info') modifiers.push('no-icon-border');

	if (Icon) modifiers.push('icon');

	let class_name = bemit('c-message', modifiers);

	if (props.className) class_name += ' ' + props.className;

	let output =
	(
		<div className={ class_name } style={ props.style }>

			{ icon }

			<div className="c-message__content">
				{ props.children }
			</div>

		</div>
	);

	return output;
}