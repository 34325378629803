import { memo } from 'react'

import getImageSrcSetSizes from '../Functions/getImageSrcSetSizes'

export default memo(function Image ({ src_config, ...props })
{
	const src =  props.src || '';

	const [srcset, sizes] = getImageSrcSetSizes(src, src_config);

	return (
		<img { ...props } src={ src } alt={ props.alt }
			srcSet={ srcset } sizes={ sizes }
		/>
	);
});
