import { memo } from 'react'

import { BsExclamationLg } from 'react-icons/bs'

import bemit from '../Functions/bemit'

import Message from './Message'

export default memo(function ErrorTile ({ ...props })
{
	const Icon = props.Icon || BsExclamationLg;

	const BtnIcon = props.BtnIcon;

	const btn_modifiers = BtnIcon ? ['icon-left'] : [];

	const btn_class = props['btn-class'] || 'c-flat-btn';

	const modifiers = ['bg-white', 'red'].concat(props.bemit || ['page-error']);

	return (
		<div className={ bemit('c-tile', modifiers) }>

			<div className="c-tile__icon-container">
				<Icon className="c-tile__icon"
					data-icon={ props['icon-name'] || 'BsExclamationLg' }
				/>
			</div>

			<h2 className="c-tile__title">
				{ props.title }
				<span className="c-tile__sep" />
			</h2>

			<div className="c-tile__content">
				<Message color="red" center-msg={ props['center-msg'] }>
					{ props.error }
				</Message>

				<div className="u-flex u-content-center u-margin-top-20">
					<a href={ props['btn-href'] || '/login' }
						className={ bemit(btn_class, btn_modifiers) }>

						{
							BtnIcon
							? <BtnIcon className="c-flat-btn__icon" />
							: undefined
						}

						<span className="c-btn__label">
							{ props['btn-label'] || 'Revenir à l\'accueil' }
						</span>
					</a>
				</div>

			</div>

		</div>
	);
});