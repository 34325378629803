import { DateTime } from 'luxon'

import { Store } from 'pullstate'

import getSessionObject from '../Components/Functions/getSessionObject'

const RolesStore = new Store(function initStore ()
{
	const store =
	{
		roles: [],
		status: 'pending'
	};

	const home   = window.localStorage.getItem('roles@home');
	const date   = DateTime.fromISO(window.localStorage.getItem('roles@date'));
	const status = window.localStorage.getItem('roles@status');

	const expired = date.isValid
	? date.plus({ hours: 24 }) < DateTime.now()
	: true;

	if (expired) return store;

	store.roles = getSessionObject('roles');

	if (!Array.isArray(store.roles)) store.roles = [];

	if (home) store.home = home;
	if (status) store.status = status;
	if (date.isValid) store.date = date.toISO();

	store.user_id = window.localStorage.getItem('roles@user_id') || '';

	return store;
}());

export default RolesStore;