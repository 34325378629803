export default function getSessionObject (name)
{
	try
	{
		var value = JSON.parse(window.localStorage.getItem(name) || '{ }')
	}
	catch (e)
	{
		return undefined;
	}

	return value;
}