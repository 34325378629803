import { DateTime } from 'luxon'

import getSessionObject from '../Components/Functions/getSessionObject'

export default function getPagesStoreInit ()
{
	const store =
	{
		pages: [],
		status: 'pending'
	};

	const version = window.localStorage.getItem('pages@version');
	const date    = DateTime.fromISO(window.localStorage.getItem('pages@date'));
	const status  = window.localStorage.getItem('pages@status');

	const expired = date.isValid
	? date.plus({ hours: 24 }) < DateTime.now()
	: true;

	if (expired) return store;

	store.pages = getSessionObject('pages');

	if (!Array.isArray(store.pages)) store.pages = [];

	if (version) store.version = version;
	if (status) store.status = status;
	if (date.isValid) store.date = date.toISO();

	return store;
}