import { DateTime } from 'luxon'

import VersionsStore from '../Stores/VersionsStore'

import { js_version } from '../shared_config'

export default function setVersionsStore ({ version })
{
	const status = js_version !== version
	? 'invalid'
	: version
	? 'up-to-date'
	: 'pending';

	VersionsStore.update(function (store)
	{
		store.version = version;
		store.date = DateTime.now().toISO();
		store.status = status;

		window.localStorage.setItem('jsversion', version);
		window.localStorage.setItem('jsversion@date', store.date);
		window.localStorage.setItem('jsversion@status', store.status);
	});
}