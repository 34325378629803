const DESKTOP_MAX_W = 2000;

const breakpoints = [369, 599, 901, 1200, 1536, 2000, 2600, 3800];

export default function getImageSrcSetSizes (src, config)
{
	if (typeof src !== 'string' || !config || typeof config !== 'object')
	{
		return [];
	}

	const { original, desktop, available=[] } = config;

	if (original > 0 === false || desktop > 0 === false) return [];

	let srcset = src + ' ' + original + 'w';

	if (Array.isArray(available))
	{
		available.forEach(function (size, i)
		{
			if (size === original) return;

			const new_src = src.includes('.') === false
			? src + '@' + size
			: src.slice(0, src.lastIndexOf('.')) + '@' + size + src
			.slice(src.lastIndexOf('.'));

			srcset += ', ' + new_src + ' ' + size + 'w';
		});
	}

	let sizes = '';

	const available_widths = available.includes(original)
	? available
	: [original].concat(available);

	breakpoints.forEach(function (screen_w, i)
	{
	// estimation de la taille d'affichage en fonction de la taille ciblée
		let target = screen_w > DESKTOP_MAX_W
		? desktop * (screen_w / DESKTOP_MAX_W)
		: desktop;

		if (target > screen_w)
		{
			target = screen_w;
		}

		const max_width = (target > screen_w ? screen_w : target);

		const prev_screen_w = parseInt(breakpoints[i-1]);

		const prev_max_width = target > prev_screen_w
		? prev_screen_w
		: target;

		const width = i === 0 || i === breakpoints.length-1
		? nearest(max_width, available_widths)
		: getNearestBondaries(max_width, prev_max_width, available_widths);

		if (i === 0) // taille min
		{
			sizes += '(max-width: ' + screen_w + 'px) ' + width + 'px, ';
		}
		else if (i === breakpoints.length-1) // taille max
		{
			sizes += '(min-width: ' + (prev_screen_w+1) + 'px) ' + width + 'px';
		}
		else // entre deux
		{
			sizes += '((min-width: ' + prev_screen_w + 'px) and ' +
				'(max-width: ' + screen_w + 'px)) ' + width + 'px, ';
		}
	});

	return [srcset, sizes];
}

function nearest (size, widths=[])
{
	const nearest = widths.reduce(function (nearest, other_size)
	{
		if (other_size === nearest) return nearest;

		let difference = size - other_size;
		if (difference < 0) difference *= -1;

		let near_difference = size - nearest;
		if (near_difference < 0) near_difference *= -1;

		return difference < near_difference
		? other_size
		: nearest;
	},
	widths[0]);

	if (typeof window === 'undefined') return nearest;

// Améliore la qualité : évite d'utiliser une taille sensiblement plus petite
// si la densité de pixel de l'affichage est faible
	if (window.devicePixelRatio < 1.4 && nearest < (size*0.78))
	{
		const index = widths.find(w => w === nearest);

		return widths[index+1] || nearest;
	}

	return nearest;
}

function getNearestBondaries (size_a, size_b, widths)
{
	if (!size_a && !size_b) return 0;

	if (!size_a) return nearest(size_a, widths)

	if (!size_b) return nearest(size_a, widths)

	const nearest_a = nearest(size_a, widths);
	const nearest_b = nearest(size_b, widths);

	return nearest_b > nearest_a ? nearest_b : nearest_a;
}