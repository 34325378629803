import React from 'react'

import { createRoot } from 'react-dom/client'

import App from './App'

document.addEventListener('DOMContentLoaded', function ()
{
	const root_node = document.getElementById('root');

	if (!root_node) return console.error('missing #root <div> on the page');

	const root = createRoot(root_node);

	root.render(<App />);
});