// @ts-check

/**
 * @param { Date } start_time=0 Date at which the request was started
 * @param { Number } MIN_REQ_TIME=800 minimum request time
 *
 * @returns { Number } duration in ms
 */
export default function getTimeout (start_time, MIN_REQ_TIME=800)
{
/** @type { Number } **/
	const elapsed_time = (new Date()).getTime() - start_time.getTime();

	let timeout_duration = 0;

	if (elapsed_time <= 0 || isNaN(elapsed_time))
	{
		timeout_duration = 0;
	}
	else if (elapsed_time >= MIN_REQ_TIME)
	{
		timeout_duration = 0;
	}
	else
	{
		timeout_duration = MIN_REQ_TIME - elapsed_time;
	}

	return timeout_duration;
}