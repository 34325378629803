import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'

import ErrorTile from '../../Pure/ErrorTile'
import Image     from '../../Pure/Image'

const img_config =
{
	base: 1000,
	target: 1000,

	available: [2000]
};

const DEFAULT_MSG = 'Nous n\'avons pas pu trouver la page demandée.';

export default function NotFoundPage (props)
{
	const msg = props.error || DEFAULT_MSG;

	return (
		<main id="main" role="main"
			className="page-content page-content--error">

			<div className="error-page__content">
				<ErrorTile title="Page introuvable"
					error={ msg }
					center-msg={ props.center }
					BtnIcon={ HiOutlineArrowNarrowLeft }
				/>
			</div>

			<Image alt="" className="error-page__image"
				src="/images/status/not_found.jpg"
				src_config={ img_config }
			/>

		</main>
	);
}