export default function nodeIsOrBelongsTo (node, parent)
{
	let is__self = node === parent;

	if (is__self) return true;

	let el = node;

	while (el && el.parentElement)
	{
		if (el.parentElement === parent)
		{
			is__self = true;
			break;
		}

		el = el.parentElement;
	}

	return is__self === true;
}