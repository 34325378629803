import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'

import RolesStore from '../../../Stores/Roles'

import useMyHome from '../../../Hooks/useMyHome'

import ErrorTile from '../../Pure/ErrorTile'
import Image     from '../../Pure/Image'

const img_config =
{
	original: 1000,
	desktop: 1000,

	available: [500, 1000, 1500, 2000, 3000]
};

const DEFAULT_MSG = 'Connectez vous avant de tenter d\'accéder à cette page.';

export default function NotAllowedPage (props)
{
	const my_roles = RolesStore.useState(s => s.roles || []);
	const my_home = useMyHome();

	const msg = props.error
	? props.error
	: my_roles.length === 0
	? DEFAULT_MSG
	: 'Cette page ne vous est pas accessible.';

	return (
		<main id="main" role="main"
			className="page-content page-content--error">

			<div className="error-page__content">
				<ErrorTile title="Accès refusé"
					error={ msg }
					BtnIcon={ HiOutlineArrowNarrowLeft }
					btn-label={ my_roles.length ? undefined : 'Page de connexion' }
					btn-href={ my_roles.length ? my_home : undefined }
				/>
			</div>

			<Image alt="" className="error-page__image"
				src="/images/status/not_allowed.jpg"
				src_config={ img_config }
			/>

		</main>
	);
}