import { useEffect, useCallback, memo } from 'react'

import { RxUpdate } from 'react-icons/rx'

import { DateTime } from 'luxon'

import invalidateVersionsStore from '../../StoresFunctions/invalidateVersionsStore'
import setVersionsStore        from '../../StoresFunctions/setVersionsStore'
import VersionsStore           from '../../Stores/VersionsStore'

import request from '../Functions/ajax/request'
import bemit   from '../Functions/bemit'

import { js_refresh_rate } from '../../shared_config'

export default memo(function AppVersion ()
{
	const status = VersionsStore.useState(s => s.status);
	const date   = VersionsStore.useState(s => s.date);

	const scheduleUpdate = useCallback(function (date, status)
	{
		const expiration = DateTime.fromISO(date).plus(js_refresh_rate);

		const ms = expiration
		.diff(DateTime.now(), 'milliseconds')
		.as('milliseconds');

		if (status === 'invalid')
		{
			return window.setTimeout(function ()
			{
				const status = window.localStorage.getItem('jsversion@status');

				if (status === 'up-to-date')
				{
					invalidateVersionsStore();
				}
			},
			30 * 1000);
		}
		else if (ms < 0) // date d'expiration dépassée
		{
			const status = window.localStorage.getItem('jsversion@status');

			if (status === 'up-to-date')
			{
				invalidateVersionsStore();
			}

			return;
		}

		return window.setTimeout(function ()
		{
			const date = window.localStorage.getItem('jsversion@date');
			const status = window.localStorage.getItem('jsversion@status');

			const expiration = DateTime.fromISO(date).plus(js_refresh_rate);

			const expired = date && DateTime.now() > expiration;

		// on a dépassé la date d'expiration, on force l'expiration du state
		// pour qu'un appel au serveur puisse confirmer si on est à jour ou non
			if (expired && status === 'up-to-date')
			{
				invalidateVersionsStore();
			}
		},
		ms);
	},
	[]);

	useEffect(function ()
	{
		const timeout = status !== 'pending'
		? scheduleUpdate(date, status)
		: undefined;

		if (status !== 'pending') return () => window.clearTimeout(timeout);

		const controller = new AbortController();

		(async function fetchRoles ()
		{
			try
			{
				var { version } = await request('/get-js-version')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				if (controller?.signal?.aborted) return;
				return;
			}

			setVersionsStore({ version });
		})();

		return () => { controller?.abort?.(); window.clearTimeout(timeout); };
	},
	[status, date, scheduleUpdate]);

	const reload = useCallback(function (e)
	{
		window.localStorage.removeItem('jsversion');
		window.localStorage.removeItem('jsversion@date');
		window.localStorage.removeItem('jsversion@status');

		document.location.reload(true);
	},
	[]);

	if (!date || status === 'pending') return null;

	const needs_reload = status === 'invalid'
	? true
	: DateTime.now() > DateTime.fromISO(date).plus(js_refresh_rate);

	if (needs_reload)
	{
		return (
			<div className="o-info-alert" role="alert">
				<p className="o-info-alert__text">
					Nouvelle version disponible
				</p>
				<button type="button" onClick={ reload }
					className={
						bemit('c-flat-btn', ['icon-left', 'green', 'js-version'])
					}>
					<RxUpdate className="c-flat-btn__icon" />
					Recharger
				</button>
			</div>
		);
	}

	return null;
});