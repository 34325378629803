import { useRef, useEffect, useCallback } from 'react'

import nodeIsOrBelongsTo from '../Functions/nodeIsOrBelongsTo'

export default function HeaderItemToggle ({ setOpened, onClick, ...props })
{
	const ref = useRef();
	const ignore_bubbling_ref = useRef();

	useEffect(() =>
	{
	// évite d'ajouter un écouteur d'évènement qui ne sert à rien
		if (props.open !== true) return;

		document.addEventListener('click', listener);

		return () => document.removeEventListener('click', listener);

		function listener (e)
		{
			if (ignore_bubbling_ref.current === true)
			{
				ignore_bubbling_ref.current = false;
				return;
			}

			if (nodeIsOrBelongsTo(e?.target, ref.current))
			{
				return; // évite de s'auto-fermer
			}

			setOpened(undefined);
		}
	},
	[ref, setOpened, props.open, ignore_bubbling_ref]);

	const handleClick = useCallback(function (e)
	{
		e?.persist?.();

	// Sans ça l'écouteur sur le document de l'effet plus haut est déclenché par
	// par ce onClick mais plus tard le temps que l'évènement remonte,
	// et possiblement même après que les props aient changé suite au click
		ignore_bubbling_ref.current = true;

		setOpened(props.open ? undefined : props.name);
	},
	[setOpened, ignore_bubbling_ref, props.open, props.name]);

	let class_name = 'c-menu-item__link';

	const Icon = props.Icon || undefined;

	if (Icon) class_name += ' has-icon';

	return (
		<button type="button" className={ class_name } ref={ ref }
			onClick={ handleClick }>
			{
				Icon
				? <Icon className="c-menu-item__icon" />
				: undefined
			}

			{ props.children }
			
		</button>
	);
}