const DeletedPage = () => 'Page supprimée.';

export const HomePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/HomePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ApiUsersListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/acces-api/ApiUsersListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewApiUserPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/acces-api/nouvel-acces-api/NewApiUserPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ApiUserPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/acces-api/[api_user_id]/ApiUserPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const AdminHomePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/accueil/AdminHomePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UsersListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-utilisateurs/UsersListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewUserPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-utilisateurs/nouvel-utilisateur/NewUserPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UserPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-utilisateurs/[user_id]/UserPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MainDocsPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/docs/v1/MainDocsPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ErrorsDocsPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/docs/v1/gestion-des-erreurs/ErrorsDocsPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const LoginPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/login/LoginPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MyAccountPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mon-compte/MyAccountPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const RequestPasswordPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mot-de-passe-oublie/RequestPasswordPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewPasswordPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mot-de-passe-oublie/nouveau-mot-de-passe/[token]/NewPasswordPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

const ROUTES = { HomePage, ApiUsersListPage, NewApiUserPage, ApiUserPage, AdminHomePage, UsersListPage, NewUserPage, UserPage, MainDocsPage, ErrorsDocsPage, LoginPage, MyAccountPage, RequestPasswordPage, NewPasswordPage };
export default ROUTES;