import { DateTime } from 'luxon'

import VersionsStore from '../Stores/VersionsStore'

export default function invalidateVersionsStore ()
{
	VersionsStore.update(function (store)
	{
		store.date = DateTime.now().plus({ years: -10 });
		store.status = 'pending';

		window.localStorage.setItem('jsversion@date', store.date);
		window.localStorage.setItem('jsversion@status', store.status);
	});
}