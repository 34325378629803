import { DateTime } from 'luxon'

import { js_version, js_refresh_rate } from '../shared_config'

export default function getVersionsStoreInit ()
{
	const store =
	{
		status: 'pending',
		version: js_version
	};

	const version = window.localStorage.getItem('jsversion');
	const date    = DateTime.fromISO(window.localStorage.getItem('jsversion@date'));
	const status  = window.localStorage.getItem('jsversion@status');

	if (version && version !== js_version)
	{
		window.localStorage.setItem('jsversion', version);
	}

	const expired = date.isValid
	? date.plus(js_refresh_rate) < DateTime.now()
	: true;

	if (expired) return store;

	if (version) store.version = version;
	if (status) store.status = status;
	if (date.isValid) store.date = date.toISO();

	return store;
}