export default function matchPage (pages=[], input_path)
{
// si le chemin fourni n'est pas du même domaine il ne sera pas dans pages
// on retourne null pour différencier avec une page normale mais non trouvée
	if (input_path.startsWith('http') &&
		!input_path.startsWith(document.location.origin))
	{
		return null;
	}

	let pathname = input_path.startsWith('http')
	? input_path.split('//')[1]
	: input_path;

	if (!pathname.startsWith('/')) pathname = '/' + pathname;

	if (pathname !== '/' && pathname.endsWith('/')) pathname = pathname.slice(0, -1);

	return pages.find(function (path)
	{
	// exception pour l'accueil
		if (pathname === '/') return path === '/';

		if (path === '/') return false; // géré au dessus

		const location_slugs = pathname.split('/').slice(1);
		const page_slugs = path.split('/').slice(1);

	// pas la même profondeur d'url
		if (location_slugs.length !== page_slugs.length) return false;

		return page_slugs.every(function (page_slug, i)
		{
			return page_slug.startsWith('[')
			? true
			: page_slug === location_slugs[i];
		});
	});
}