import { DateTime } from 'luxon'

import PagesStore from '../Stores/Pages'

export default function setPagesStore ({ pages=[], version })
{
	if (!Array.isArray(pages) || !version)
	{
		return console.error('setPagesStore pages & version required.');
	}

	PagesStore.update(function (store)
	{
		const dirty = version !== store.version;

		store.pages = Array.isArray(pages) ? pages : [];
		store.date = DateTime.now().toISO();
		store.version = version;
		store.status = 'fetched';

		if (dirty)
		{
			try
			{
				window.localStorage.setItem('pages', JSON.stringify(store.pages));
			}
			catch (e)
			{
				console.error('Impossible d\'enregistrer les pages accessibles');
			}
		}

		window.localStorage.setItem('pages@date', store.date);
		window.localStorage.setItem('pages@version', store.version);
		window.localStorage.setItem('pages@status', store.status);
	});
}