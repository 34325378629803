import { useEffect } from 'react'

import ApiSidebarMenu from './ApiSidebarMenu'
import ApiTopLogo     from '../ApiTopLogo'

import DocsStore from '../../../Stores/Docs'

export default function ApiSidebar (props)
{
	const is__open = DocsStore.useState(s => s.sidebar === true);

	useEffect(function ()
	{
		window.localStorage.setItem('docs@sidebar', !is__open ? 'closed' : 'open');
	},
	[is__open]);

	return (
		<div id="docs-sidebar" className="c-api-page__sidebar"
			aria-hidden={ !is__open ? 'true' : 'false' }>

			<ApiTopLogo />

			<ApiSidebarMenu />

			<div className="c-api-page__sidebar-footer">
				by myWO
			</div>

		</div>
	);
}