import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'

import ErrorTile from '../../Pure/ErrorTile'
import Image     from '../../Pure/Image'

const img_config =
{
	original: 1000,
	desktop: 1000,

	available: [500, 1000, 1500, 2000, 3000]
};

const DEFAULT_MSG = 'Nous ne sommes pas en mesure d\'afficher la page que vous demandez.';

export default function UnavailablePage (props)
{
	const msg = props.error || DEFAULT_MSG;

	return (
		<main id="main" role="main"
			className="page-content page-content--error">

			<div className="error-page__content">
				<ErrorTile title="Page inaccessible"
					error={ msg }
					BtnIcon={ HiOutlineArrowNarrowLeft }
					btn-label="Page de connexion"
				/>
			</div>

			<Image alt="" className="error-page__image"
				src="/images/status/unavailable.jpg"
				src_config={ img_config }
			/>

		</main>
	);
}